<template>
  <Header :nav="2" />
  <div class="banner" />
  <div class="c_body">
    <div class="profile">
      <div class="profile_icon" />
      <div class="profile_line1">COMPANY PROFILE</div>
      <div class="profile_line2">
        公司简介
      </div>
    </div>
    <div class="profile_detail">
      <p class="profile_p1">
        凌光红外成立于2021年12月，公司始终坚持正向研发，专注于高端实验室检测仪器的国产化，解决高精设备迫在眉睫的卡脖子问题。目前公司主要设计生产半导体电性失效分析设备。
      </p>
    </div>
    <div class="about_us" />
    <div class="introduce">
      <div class="title1">
        SENIOR TEAM<br />
        创始团队
      </div>
      <div class="line">
        <img src="../assets/photo1.png" alt class="img_left" />
        <div class="right_area">
          <div class="dot" />
          <p class="name_title">侯达之&nbsp;&nbsp;&nbsp;&nbsp;首席科学家</p>
          <div class="dec">
            中国锁相红外领域专家，锁相荧光创始人<br />
            复旦物理系本科及博士，日本东北大学助理教授<br />
            中国科学技术大学特任教授<br />
            十六届中组部青年千人计划入选者<br />
            国际顶级期刊34篇，引用次数1500+
          </div>
        </div>
      </div>
      <div class="line">
        <img src="../assets/photo2.png" alt class="img_right" />
        <div class="left_area">
          <div class="dot" />
          <p class="name_title">刘祥安&nbsp;&nbsp;&nbsp;&nbsp;董事长&CEO</p>
          <div class="dec">
            复旦大学软件工程本科<br />
            哥伦比亚大学计算机硕士<br />
            前Facebook机器学习工程师<br />
            在SAP、Petuum多家公司有丰富的<br />
            从零打造产品经验
          </div>
        </div>
      </div>
      <div class="line">
        <img src="../assets/photo3.png" alt class="img_left" />
        <div class="right_area">
          <div class="dot" />
          <p class="name_title">沈金辉&nbsp;&nbsp;&nbsp;&nbsp;首席技术官</p>
          <div class="dec">
            复旦大学物理系本科及博士<br />
            德国慕尼黑工业大学访问学者<br />
            发表顶级物理期刊，有极强的动手实验能力<br />
            曾就职于上海微电子装备
          </div>
        </div>
      </div>
      <div class="line">
        <img src="../assets/photo4.png" alt class="img_right" />
        <div class="left_area">
          <div class="dot" />
          <p class="name_title">杨向群&nbsp;&nbsp;&nbsp;副总&首席营销官</p>
          <div class="dec">
            工商管理博士，电子工程硕士<br />
            中国半导体失效分析业务领头人<br />
            15年EFA从业经验
          </div>
        </div>
      </div>
      <div class="title2">
        SENIOR TEAM<br />
        资深团队
      </div>
      <div class="team" />
      <div class="team_dec">
        公司团队来自复旦，交大，浙大等国内知名高校，在各自行业内均有十数年的丰富从业经验。
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { useRoute } from "vue-router";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default {
  setup() {
    const route = useRoute();
    const getParams = () => {
      return route.params;
    };
    return {
      getParams,
    };
  },
  name: "About",
  components: {
    Header,
    Footer,
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  height: 260px;
  background: url("../assets/banner2.png") center center no-repeat;
  background-size: cover;
}

.c_body {
  width: 858px;
  margin: 0 auto;
  text-align: left;
  .profile {
    position: relative;
    width: 100%;
    height: 180px;
    border-bottom: 1px solid #aaaaaa;
    .profile_icon {
      position: absolute;
      top: 51px;
      left: 0;
      width: 40px;
      height: 73px;
      background: url("../assets/profile.png") center center no-repeat;
      background-size: contain;
    }
    .profile_line1 {
      position: absolute;
      top: 51px;
      left: 54px;
      font-size: 32px;
      color: #323044;
      line-height: 32px;
    }
    .profile_line2 {
      position: absolute;
      bottom: 56px;
      left: 54px;
      font-size: 24px;
      color: #323044;
      line-height: 24px;
      > span {
        font-size: 18px;
      }
    }
  }
  .profile_detail {
    font-size: 20px;
    color: #323044;
    line-height: 38px;
    margin: 60px auto 30px;
    .profile_p1 {
      text-indent: 2em;
    }
  }
  .about_us {
    width: 858px;
    height: 552px;
    background: url("../assets/about_us.png") center center no-repeat;
    background-size: contain;
  }
  .introduce {
    width: 782px;
    height: auto;
    margin: 0 auto;
    .title1 {
      width: 100%;
      height: 75px;
      margin: 107px auto 104px;
      padding-left: 114px;
      font-size: 36px;
      color: #323044;
      line-height: 40px;
      font-weight: bold;
      background: url("../assets/01.png") left center no-repeat;
      background-size: auto 100%;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .line {
      width: 100%;
      height: 245px;
      margin-bottom: 150px;
      .img_left {
        display: block;
        float: left;
      }
      .img_right {
        display: block;
        float: right;
      }
      .left_area {
        width: 500px;
        height: 245px;
        float: left;
      }
      .right_area {
        width: 422px;
        height: 245px;
        float: right;
      }
      .dot {
        width: 35px;
        height: 35px;
        background-color: #c31920;
        margin-right: 40px;
        float: left;
      }
      .name_title {
        font-size: 36px;
        font-weight: bold;
        color: #323044;
        line-height: 36px;
        float: left;
      }
      .dec {
        font-size: 18px;
        color: #323044;
        line-height: 36px;
        padding-top: 30px;
        clear: both;
      }
    }
    .title2 {
      width: 100%;
      height: 75px;
      margin: 0 auto 59px;
      padding-left: 114px;
      font-size: 36px;
      color: #323044;
      line-height: 40px;
      font-weight: bold;
      background: url("../assets/02.png") left center no-repeat;
      background-size: auto 100%;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .team {
      width: 782px;
      height: 350px;
      margin: 0 auto;
      background: url("../assets/team.png") center center no-repeat;
      background-size: contain;
    }
    .team_dec {
      font-size: 20px;
      color: #323044;
      line-height: 38px;
      margin-top: 20px;
      margin-bottom: 100px;
      letter-spacing: 1.2px;
    }
  }
}
</style>
