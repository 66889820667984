<template>
  <Header :nav="3" />
  <div class="banner" />
  <div class="c_body">
    <div class="profile">
      <div class="profile_icon" />
      <div class="profile_line1">NEWS DYNAMICS</div>
      <div class="profile_line2">新闻动态</div>
    </div>
    <div class="news_frame">
      <div class="title">{{ news.title }}</div>
      <div class="date">
        发布时间：{{ convertTime(news.publish_at, "YYYY年MM月DD日") }}
      </div>
      <img :src="news.cover" alt="" />
      <div class="content">
        <p class="entry-content" v-html="news.content" />
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { useRoute } from "vue-router";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { GET_NEWS_ID } from "../http/api";
import moment from "moment";
import decode from "decode-html";

export default {
  setup() {
    const route = useRoute();
    const getParams = () => {
      return route.params;
    };
    return {
      getParams,
    };
  },
  name: "About",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      news: {
        brief: "",
        content: "",
        cover: "",
        id: 0,
        publish_at: "",
        title: "",
      },
    };
  },
  methods: {
    convertTime(timeStr, formatStr) {
      return moment(timeStr).format(formatStr);
    },
  },
  created: function () {},
  mounted: function () {
    let id = this.getParams().id;
    GET_NEWS_ID(id).then((res) => {
      this.news = res;
      this.news.content = decode(res.content);
    });
  },
};
</script>
<style scoped lang="scss">
.banner {
  width: 100%;
  height: 260px;
  background: url("../assets/banner5.png") center center no-repeat;
  background-size: cover;
}
.c_body {
  width: 858px;
  margin: 0 auto;
  text-align: left;
  .profile {
    position: relative;
    width: 100%;
    height: 180px;
    border-bottom: 1px solid #aaaaaa;
    .profile_icon {
      position: absolute;
      top: 51px;
      left: 0;
      width: 40px;
      height: 73px;
      background: url("../assets/profile.png") center center no-repeat;
      background-size: contain;
    }
    .profile_line1 {
      position: absolute;
      top: 51px;
      left: 54px;
      font-size: 32px;
      color: #323044;
      line-height: 32px;
    }
    .profile_line2 {
      position: absolute;
      bottom: 56px;
      left: 54px;
      font-size: 24px;
      color: #323044;
      line-height: 24px;
      > span {
        font-size: 18px;
      }
    }
  }
  .news_frame {
    width: 100%;
    padding-top: 60px;
    .title {
      width: 100%;
      font-size: 24px;
      font-weight: bold;
      color: #383838;
      line-height: 36px;
      margin-bottom: 30px;
      text-align: center;
    }
    .date {
      width: 100%;
      height: 18px;
      font-size: 18px;
      color: #c31920;
      line-height: 18px;
      padding-bottom: 33px;
      text-align: center;
      border-bottom: 2px solid #323044;
    }
    img {
      width: 100%;
      height: auto;
      margin: 15px auto;
      display: block;
    }
    .content {
      width: 100%;
      padding: 20px 0;
      .entry-content {
        font-size: 18px;
        color: #383838;
        line-height: 35px;
        text-align: left;
        text-indent: 2em;
      }
    }
  }
}
</style>
